<template>
  <v-container class="fill" fluid>
    <!-- FORMULARIO DE FILTRO -->
    <v-card class="mx-auto mb-3" elevation="5" outlined>
      <v-toolbar color="indigo darken-4" dark dense>
        <v-toolbar-title>Cobros TPV</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-list-item three-line>
        <v-list-item-content>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.numero_poliza"
                label="Numero de Póliza"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="filtro.nombre"
                label="Nombre"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="filtro.ramo"
                label="Ramo"
                :items="ramos"
                dense
                outlined
                :menu-props="{ closeOnContentClick: true }"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="filtro.ramo = null">
                    <v-list-item-content>
                      <v-list-item-title>TODOS</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="filtro.estado"
                label="Estado"
                :items="estados"
                item-text="name"
                dense
                outlined
                :menu-props="{ closeOnContentClick: true }"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="filtro.estado = null">
                    <v-list-item-content>
                      <v-list-item-title>TODOS</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.fecha_ini"
                type="date"
                label="Fecha desde"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.fecha_fin"
                type="date"
                label="Fecha hasta"
                dense
                outlined
                class="text-center"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="filtro.email"
                label="eMail"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                v-model="filtro.pago_referencia"
                label="Ref. Propia"
                dense
                outlined
                class="text-center"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-tooltip top color="blue">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right ml-1"
                    color="blue"
                    @click="refrescarDatos"
                    dark
                    v-on="on"
                  >
                    <v-icon dark>mdi-autorenew</v-icon>
                  </v-btn>
                </template>
                <span>Actualizar datos</span>
              </v-tooltip>
              <v-tooltip left color="purple">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right"
                    color="purple"
                    @click="resetFiltros"
                    dark
                    v-on="on"
                  >
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Limpiar formulario</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="loading"> </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-row>
        <v-col cols="12">
          <cobros-table :valores="valores"></cobros-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import _ from "lodash";

import CobrosTable from "@/components/cobros/parts/CobrosTable";
export default {
  name: "Cobros",

  components: {
    CobrosTable,
  },

  data: () => ({
    loading: true,
    datos: null,
    datosTratados: null,
    estados: null,
    ramos: null,

    filtro: {
      numero_poliza: null,
      nombre: null,
      ramo: null,
      fecha_ini: null,
      fecha_fin: null,
      estado: null,
      email: null,
      pago_referencia: null,
    },
  }),

  methods: {
    ...mapActions("cobros", ["setCobrosAll"]),

    /* refrescar datos */
    refrescarDatos() {
      this.resetFiltros();
      this.loading = true;
      this.$store.commit("comun/SET_MAIN_LOADER", true);
      this.setCobrosAll().then((res) => {
        this.datos = res.cobros;
        this.estados = res.estados;
        this.ramos = _.map(res.ramos, (r) => r.toUpperCase());
        this.datosTratados = this.generarDatosTratados();
        this.$store.commit("comun/SET_MAIN_LOADER", false);
        this.loading = false;
      });
    },

    /* limpieza de los datos del form de búsqueda */
    resetFiltros() {
      Object.keys(this.filtro).forEach((e) => (this.filtro[e] = null));
    },

    generarDatosTratados() {
      const salida = this.datos.reduce((acc, curr) => {
        const fecha = moment(curr.fecha, "YYYY-MM-DD HH:mm:ss");
        acc.push({
          fecha: fecha.format("DD/MM/YYYY"),
          hora: fecha.format("HH:mm:ss"),
          numero_poliza: curr.numero_poliza,
          ramo: curr.tipo_seguro,
          nombre: curr.nombre,
          email: curr.email,
          estado: curr.pago_estado,
          importe: curr.importe.toFixed(2),
          pago_referencia: curr.pago_referencia,
          cod_autorizacion: curr.cod_autorizacion,
          cod_respuesta_tpv: curr.cod_respuesta_tpv,
        });

        return acc;
      }, []);

      return salida;
    },
  },

  computed: {
    valores() {
      if (this.datos === null && this.datosTratados === null) {
        return null;
      }
      let tmpFiltro = {};
      Object.keys(this.filtro).forEach((e) => {
        if (
          !!this.filtro[e] &&
          (this.filtro[e].length > 0 || Number.isInteger(this.filtro[e]))
        ) {
          const tKey = e;
          tmpFiltro[tKey] = this.filtro[e];
        }
      });
      return filtros(this.datosTratados, tmpFiltro);
    },
  },

  mounted() {
    this.refrescarDatos();
  },
};
/* funcion para filtras los datos */
const filtros = (data, filtros) => {
  var filterKeys = Object.keys(filtros);
  //console.log(filterKeys, filtros);
  const salida = data.filter(function (eachObj) {
    return filterKeys.every(function (eachKey) {
      //comprobamos que el filtro actual no esté vacio
      if (!filtros[eachKey].length && !Number.isInteger(filtros[eachKey])) {
        return true;
      }

      //comprobamos que el filtro actual sea o no de fecha
      if (eachKey.includes("fecha")) {
        return compararFechas(
          filtros.fecha_ini,
          filtros.fecha_fin,
          eachObj.fecha
        );
      }

      //comprobamos si es un filtro de especialidades
      if (eachKey === "specialties") {
        if (eachObj[eachKey].indexOf(filtros[eachKey]) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      //comparamos un texto cualquiera
      if (compararRegexStr(filtros[eachKey], eachObj[eachKey])) {
        return true;
      }
    });
  });
  return salida;
};

/* funcion para comparar via regex los strings */
const compararRegexStr = (cadena, texto) => {
  let pattern = texto.toLowerCase();
  pattern = pattern.replace(/[á]/, "a");
  pattern = pattern.replace(/[é]/, "e");
  pattern = pattern.replace(/[í]/, "i");
  pattern = pattern.replace(/[ó]/, "o");
  pattern = pattern.replace(/[ú]/, "u");

  let _value_ = cadena.toLowerCase();
  _value_ = _value_.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  _value_ = _value_.replace(/[á]/, "a");
  _value_ = _value_.replace(/[é]/, "e");
  _value_ = _value_.replace(/[í]/, "i");
  _value_ = _value_.replace(/[ó]/, "o");
  _value_ = _value_.replace(/[ú]/, "u");
  _value_ = new RegExp(_value_, "gi");

  const salida = pattern.match(_value_);
  if (salida !== null) {
    return salida;
  }
};

/* funcion para comparar fechas */
const compararFechas = (fechaIni, fechaFin, fechaAlta) => {
  //si la fecha de inicio no está seteada devolvemos true y no comparamos
  if (!fechaIni) {
    return true;
  }

  //si no hay fecha fin comparamos las fechas mayor e igula que fechaAlta
  if (!fechaFin) {
    const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
    const mFechaAlta = moment(fechaAlta, "DD/MM/YYYY");
    //console.log(mFechaAlta.isAfter(mFechaIni));
    return mFechaAlta.isAfter(mFechaIni);
  }
  //si está seteada tbn la fecha de fin
  const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
  const mFechaAlta = moment(fechaAlta, "DD/MM/YYYY");
  const mFechaFin = moment(fechaFin, "YYYY-MM-DD").add(1, "d");

  return mFechaAlta.isAfter(mFechaIni) && mFechaAlta.isBefore(mFechaFin);

  return true;
};
</script>

<style scoped>
.v-input {
  font-size: 0.9em;
}
/deep/.v-label {
  font-size: 1em;
}
</style>
