var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.valores !== null)?_c('v-data-table',{attrs:{"dense":"","fixed-header":"","multi-sort":"","headers":_vm.headers,"items":_vm.valores,"locale":"es-es","loading":_vm.cargando,"loading-text":"Loading... Please wait","items-per-page":10,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-minus',
    nextIcon: 'mdi-plus',
    'items-per-page-options': [15, 30, 50, 100],
    'items-per-page-text': 'Filas por página'
  }},scopedSlots:_vm._u([{key:"item.ramo",fn:function({ item }){return [_c('v-chip',{attrs:{"label":"","x-small":"","color":_vm.getColor(item.ramo),"dark":""}},[_vm._v(_vm._s(item.ramo))])]}},{key:"item.estado",fn:function({ item }){return [_c('v-chip',{attrs:{"label":"","x-small":"","color":_vm.getEstado(item.estado),"dark":item.estado !== 'INICIADO'}},[_vm._v(_vm._s(item.estado))])]}},{key:"item.importe",fn:function({ item }){return [_c('b',[_vm._v(_vm._s(item.importe)+" € ")])]}}],null,false,4233786253)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }