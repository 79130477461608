<template>
  <v-data-table
    dense
    fixed-header
    v-if="valores !== null"
    multi-sort
    :headers="headers"
    :items="valores"
    locale="es-es"
    :loading="cargando"
    loading-text="Loading... Please wait"
    :items-per-page="10"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
      'items-per-page-options': [15, 30, 50, 100],
      'items-per-page-text': 'Filas por página'
    }"
  >
    <template v-slot:item.ramo="{ item }">
      <v-chip label x-small :color="getColor(item.ramo)" dark>{{
        item.ramo
      }}</v-chip>
    </template>
    <template v-slot:item.estado="{ item }">
      <v-chip
        label
        x-small
        :color="getEstado(item.estado)"
        :dark="item.estado !== 'INICIADO'"
        >{{ item.estado }}</v-chip
      >
    </template>
    <template v-slot:item.importe="{ item }">
      <b>{{ item.importe }} € </b>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "Ficha-table",
  props: ["valores", "cargando"],
  data: () => ({
    headers: [
      {
        text: "Fecha",
        align: "left",
        value: "fecha"
      },
      {
        text: "hora",
        align: "left",
        value: "hora"
      },
      {
        text: "Póliza",
        align: "left",
        value: "numero_poliza"
      },
      {
        text: "Ramo",
        align: "center",
        value: "ramo"
      },
      {
        text: "Nombre",
        align: "left",
        value: "nombre"
      },
      {
        text: "eMail",
        align: "left",
        value: "email"
      },
      {
        text: "Estado",
        align: "center",
        value: "estado"
      },
      {
        text: "Importe",
        align: "right",
        value: "importe"
      },
      {
        text: "Ref.Propia",
        align: "left",
        value: "pago_referencia"
      },
      {
        text: "Ref.TPV",
        class: "mitd",
        align: "center",
        value: "cod_autorizacion"
      },
      {
        text: "C.Resp.TPV",
        align: "center",
        value: "cod_respuesta_tpv"
      }
    ]
  }),
  methods: {
    getColor(estado) {
      switch (estado) {
        case "decesos":
          return "red";
          break;
        case "salud":
          return "green";
          break;
        case "hogar":
          return "orange";
          break;
        default:
          return "blue";
          break;
      }
    },
    getEstado(estado) {
      switch (estado.toUpperCase()) {
        case "CORRECTO":
          return "green";
          break;
        case "INICIADO":
          return "yellow darken-1";
          break;
        case "CANCELADO POR USUARIO":
          return "orange";
          break;
        case "ERROR":
          return "red";
          break;
        default:
          return "blue";
          break;
      }
    },

    editar(item) {
      this.$router.push({ name: "fdetalle", params: { id: item.id } });
    }
  },
  mounted() {
    //  console.log(this.valores);
  }
};
</script>

<style scoped>
/* .v-data-table th {
  font-size: 20px;
} */

::v-deep.v-data-table td {
  font-size: 0.76em;
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
::v-deep.v-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
</style>
